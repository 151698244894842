h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

section {
  text-align: center;
}

p {
  line-height: 16px;
}

img {
  object-fit: cover;
  object-position: 50% 25%;
}

ul {
  list-style: none;
}
