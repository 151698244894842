$font_base: 'Roboto', sans-serif;
//-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Helvetica Neue, Arial, sans-serif;

// Font size
$base_font_size: 16px;
$fs_8: 8px;
$fs_9: 9px;
$fs_10: 10px;
$fs_11: 11px;
$fs_12: 12px;
$fs_13: 13px;
$fs_14: 14px;
$fs_15: 15px;
$fs_16: 16px;
$fs_18: 18px;
$fs_19: 19px;
$fs_20: 20px;
$fs_22: 22px;
$fs_24: 24px;
$fs_26: 26px;
$fs_27: 27px;
$fs_28: 28px;
$fs_30: 30px;
$fs_32: 32px;
$fs_36: 36px;
$fs_35: 35px;
$fs_48: 48px;

$fw_light: 300;
$fw_normal: 400;
$fw_medium: 500;
$fw_bold: 600;
$fw_heavy: 700;
